<template>
  <div class="merchant-container">
    <div style="height: 50px">
      <div style="float: left; margin: 5px 0 0 20px">
        <!--        <el-button icon="el-icon-plus" type="warning" @click="handleEdit" size="small">添加秒杀商品</el-button>-->
      </div>
      <div style="float: right">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item label="商品名称:">
            <el-input
                v-model.trim="queryForm.name"
                clearable
                placeholder="商品名称"
                size="small"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" @click="queryData" type="primary" size="small">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <el-table v-loading="listLoading" :data="list" @selection-change="setSelectRows">
      <el-table-column
          align="center"
          prop="id"
          label="ID"
          width="80"
      />
      <el-table-column
          align="center"
          prop="info.imgUrl"
          label="商品图片"
          width="120"
      >
        <template slot-scope="scope">
          <el-image v-if="scope.row.info.imgUrl" :src="scope.row.info.imgUrl" class="commodity_pic"/>
          <span v-else>暂无图片</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="info.name"
          label="商品名称"
      />
      <el-table-column
          align="center"
          prop="info.price"
          label="价格"
      />
      <el-table-column
          align="center"
          prop="info.stock"
          label="库存"
      />
      <el-table-column
          align="center"
          prop="purchase_num"
          label="限购数量"
      />
      <el-table-column
          align="center"
          prop="price"
          label="秒杀价格"
      />
      <el-table-column
          align="center"
          prop="inventory"
          label="秒杀库存"
      />
      <el-table-column align="center" label="操作" width="120">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">修改</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        align="center"
        :current-page="queryForm.page"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <Edit ref="Edit" @fetch-data="fetchData"/>
  </div>
</template>
<script>
import Edit from './components/goodsEdit'
import {apiDeleteSeckillGoods, apiGetSeckillGoods} from "@/request/api";

export default {
  name: 'activityGoods',
  components: {Edit},
  props: [],
  data() {
    return {
      selectRows: '',
      listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        id: this.$route.query.id,
        name: '',
      },
      list: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    handleEdit(row) {
      if (row.id) {
        this.$refs['Edit'].showEdit(row)
      } else {
        this.$refs['Edit'].showEdit()
      }
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要删除当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiDeleteSeckillGoods({id: row.id}).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.fetchData()
            } else {
              this.$message.error(res.message)
            }
          })
        })
      } else {
        this.$message.error('未选中任何行')
        return false
      }
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      apiGetSeckillGoods(this.queryForm).then(res => {
        if (res.code == 200) {
          this.list = res.data.list
          this.count = res.data.count
        }
      })
      this.listLoading = false
    },
  }
}

</script>
<style>
.commodity_pic {
  width: 60px;
  height: 60px;
}
</style>


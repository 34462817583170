<template>
  <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogFormVisible"
      width="30%"
      @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label='商品名称'>
        <el-input type="text" v-model="goodsObj.name" :disabled="true" size="small"/>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label='商品价格'>
        <el-input type="number" v-model="goodsObj.price" :disabled="true" size="small"/>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label='商品库存'>
        <el-input type="number" v-model="goodsObj.stock" :disabled="true" size="small"/>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="限购数量" prop="purchase_num">
        <el-input type="number" v-model="form.purchase_num" size="small"/>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="秒杀价格" prop="price">
        <el-input type="number" v-model="form.price" size="small"/>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="秒杀库存" prop="inventory">
        <el-input type="number" v-model="form.inventory" size="small"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">取 消</el-button>
      <el-button type="primary" @click="save" size="small">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {apiUpdateSeckillGoods} from "@/request/api";

export default {
  name: 'goodsEdit',
  data() {
    return {
      goodsObj: {
        name: '',
        price: 0,
        stock: 0,
      },
      form: {
        id: '',
        purchase_num: '',
        price: '',
        inventory: '',
      },
      rules: {
        purchase_num: [
          {required: true, trigger: 'blur', message: '限购数量不能为空'},
        ],
        price: [
          {required: true, trigger: 'blur', message: '秒杀价格不能为空'},
        ],
        inventory: [
          {required: true, trigger: 'blur', message: '秒杀库存不能为空'},
        ],
      },
      title: '',
      dialogFormVisible: false,
    }
  },
  created() {
  },
  methods: {
    showEdit(row) {
      if (!row) {
        this.title = '添加'
      } else {
        this.title = '修改'
        const tempObj = Object.assign({}, row)
        this.goodsObj = {
          name: tempObj.info.name,
          price: tempObj.info.price,
          stock: tempObj.info.stock,
        }
        this.form = {
          id: tempObj.id,
          purchase_num: tempObj.purchase_num,
          price: tempObj.price,
          inventory: tempObj.inventory,
        }
      }
      this.dialogFormVisible = true
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          apiUpdateSeckillGoods(this.form).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.$emit('fetch-data')
              this.close()
            } else {
              this.$message.error(res.message)
              this.close()
            }
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

